import React from "react"
import "./styles.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import PricingTableSection from "../../sections/pricing-table"
import QuestionsSection from "../../sections/questions"
import Footer from "../../sections/footer"
import { useTranslation } from "../../utils"
import StartSecure from "../../sections/start-secure"
import CustomPlanPricing from "../../sections/custom-plan-pricing"
import { loadStripe } from "@stripe/stripe-js"
import { CONFIG } from "../../config"
import { Elements } from "@stripe/react-stripe-js"

const stripePromise = loadStripe(CONFIG?.STRIPE_TOKEN)

const PricingPage = () => {
  const { t, locale } = useTranslation()
  return (
    <Layout>
      <Elements stripe={stripePromise} options={{ locale }}>
        <div>
          <SEO title={t("Pricing for your Security")} />
          <Header />
          <PricingTableSection />
          <CustomPlanPricing />
          <StartSecure />
          <QuestionsSection />
          <Footer />
        </div>
      </Elements>
    </Layout>
  )
}

export default PricingPage
