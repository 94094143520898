import React, { useState } from "react"
import classNames from "classnames"
import "./styles.css"
import { useTranslation } from "../../utils"
import { useAnalytics } from "../../hooks/useTracking"

const tabs = [
  {
    id: 1,
    title: "What is WebTotem?",
    description:
      "The world's friendliest professional-grade security monitoring and protection for websites.",
  },
  {
    id: 2,
    title: "Is WebTotem suitable for you?",
    description:
      "Whether you are a small business with a single site or a company with multiple sites we got you covered. We recommend using WebTotem if:\n" +
      "\n" +
      "• Your website is built on cms like WordPress, Drupal, Joomla or any other custom PHP-based solution\n" +
      "• Your website is on shared hosting or cloud\n" +
      "• Your website is the face of your business\n" +
      "• You care about your business’s reputation",
  },
  {
    id: 3,
    title: "How does it work?",
    description:
      "It’s pretty simple to get started.\n" +
      "• Choose the plan & sign up.\n" +
      "• Add your website to the dashboard.\n" +
      "• Install the agent and activate protection.",
  },
]

const QuestionsSection = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(1)
  const analytics = useAnalytics()

  return (
    <section className={classNames("questions-section")}>
      <div
        className="container"
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        {tabs.map(tab => (
          <div
            key={tab.id}
            className={`questions-section__tab ${
              activeTab === tab.id ? "active" : ""
            }`}
          >
            <div
              className={`questions-section__tab-content ${
                activeTab === tab.id ? "active" : ""
              }`}
            >
              <div className="questions-section__tab-header">
                <h5>{tab.title}</h5>
                <button
                  onClick={() => {
                    activeTab !== tab.id && analytics.showAnswer(tab.title)
                    setActiveTab(activeTab === tab.id ? 0 : tab.id)
                  }}
                  className={`questions-section__tab-header-button ${
                    activeTab === tab.id ? "active" : ""
                  }`}
                >
                  <svg
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.27441 1.25V16.25"
                      stroke={`${activeTab === tab.id ? "white" : "black"}`}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.2988 7.2998L7.27476 1.2498L1.24976 7.2998"
                      stroke={`${activeTab === tab.id ? "white" : "black"}`}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={`questions-section__tab-body ${
                  activeTab === tab.id ? "active" : ""
                }`}
              >
                <p>{tab.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default QuestionsSection
