import "./styless.css"

import React from "react"
import { openHubspotChat } from "../../utils"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useAnalytics } from "../../hooks/useTracking"

const StartSecure = () => {
  const analytics = useAnalytics()
  return (
    <section className="start-secure">
      <div className="container">
        <div className="start-secure__content">
          <h4>Secure your website.</h4>
          <p>
            Get simple and clear solution that takes care all of your security
            problems.
          </p>
          <button
            className="wt-button wt-button--white"
            onClick={() => {
              analytics.tryNow()
              scrollTo(".pricing-table-section")
            }}
          >
            <span>Try now</span>
          </button>
        </div>
      </div>
    </section>
  )
}

export default StartSecure
